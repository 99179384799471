<template>
  <div class="sign-up-management">
    <div class="sign-content">
      <p>基础信息</p>
      <el-form ref="form"
               :model="model"
               :rules="rules"
               label-width="130px"
               :inline="true">
        <el-form-item label="团队名称：" prop="groupName">
          <el-input
            v-model.trim="model.groupName"
            placeholder="请输入团队名称"
            maxlength="20"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="省份：" prop="province">
          <el-select placeholder="请输入省份" v-model="model.province" clearable>
            <el-option v-for="(item, index) in provinceOptions" :key="index" :label="item.label" :value="item.value"/>
          </el-select>
        </el-form-item>

        <el-form-item label="学校名称：" prop="orgName">
          <el-tooltip class="item" effect="dark" content="请务必填写学校全称" placement="bottom-start">
            <el-autocomplete
              v-model.trim="model.orgName"
              placeholder="请输入学校全称"
              :fetch-suggestions="querySearch"
              clearable
            ></el-autocomplete>
          </el-tooltip>
        </el-form-item>
        <el-form-item label="学院/系：" prop="college">
          <el-input
            v-model.trim="model.college"
            placeholder="请输入学院/系"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="专业名称：" prop="major">
          <el-input
            v-model.trim="model.major"
            placeholder="请输入专业名称"
            clearable
          ></el-input>
        </el-form-item>
      </el-form>

      <div class="join-people">
        <span>指导老师</span>
        <span class="add" @click="handleAddTeacher">添加老师</span>
        <el-alert
          title="请填写实名信息"
          type="warning"
          :closable="false"
          show-icon>
        </el-alert>
      </div>
      <el-table
        :data="teacherTableData"
        :header-cell-style="{'background-color':'#F8FBFF'}"
        border
        style="width: 100%; margin-top: 10px">
        <el-table-column
          label="序号"
          width="55">
          <template slot-scope="scope">
            <span>{{ scope.$index + 1 }}</span>
          </template>
        </el-table-column>
        <el-table-column label="姓名">
          <template slot-scope="{row}">
            <el-input v-model.trim="row.name" maxlength="20" placeholder="请输入姓名" clearable></el-input>
          </template>
        </el-table-column>
        <el-table-column label="性别">
          <template slot-scope="{row}">
            <el-select v-model="row.sex">
              <el-option v-for="item in genderOptions" :key="item.value" :label="item.label" :value="item.value"/>
            </el-select>
          </template>
        </el-table-column>
        <el-table-column label="手机号">
          <template slot-scope="{row}">
            <el-input v-model.trim="row.mobile" maxlength="11" placeholder="请输入手机号" clearable></el-input>
          </template>
        </el-table-column>
        <el-table-column label="身份证" prop="idNumber">
          <template slot-scope="{row}">
            <el-input v-model.trim="row.idNumber" class="long-width" maxlength="18" placeholder="请输入身份证"
                      clearable></el-input>
          </template>
        </el-table-column>
        <el-table-column label="邮箱" prop="email">
          <template slot-scope="{row}">
            <el-input v-model.trim="row.email" class="long-width" placeholder="请输入邮箱" clearable></el-input>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="100">
          <template slot-scope="scope">
            <span class="del" @click="handleDel(true, scope.$index)">删除</span>
          </template>
        </el-table-column>
      </el-table>

      <div class="join-people">
        <span>选手</span>
        <span class="add" @click="handleAddPlayer">添加选手</span>
        <el-alert
          title="请填写实名信息"
          type="warning"
          :closable="false"
          show-icon>
        </el-alert>
      </div>
      <el-table
        :data="playerTableData"
        class="player-table"
        border
        :header-cell-style="{'background-color':'#F8FBFF'}"
        style="width: 100%; margin-top: 10px">
        <el-table-column
          label="序号"
          width="55">
          <template slot-scope="scope">
            <span>{{ scope.$index + 1 }}</span>
          </template>
        </el-table-column>
        <el-table-column label="姓名" prop="name" width="180">
          <template slot-scope="{row}">
            <el-input v-model.trim="row.name" maxlength="20" placeholder="请输入姓名" clearable></el-input>
          </template>
        </el-table-column>
        <el-table-column label="性别" width="180">
          <template slot-scope="{row}">
            <el-select v-model="row.sex">
              <el-option v-for="item in genderOptions" :key="item.value" :label="item.label" :value="item.value"/>
            </el-select>
          </template>
        </el-table-column>
        <el-table-column label="手机号" prop="mobile" width="180">
          <template slot-scope="{row}">
            <el-input v-model.trim="row.mobile" maxlength="11" placeholder="请输入手机号" clearable></el-input>
          </template>
        </el-table-column>
        <el-table-column label="身份证" prop="idNumber">
          <template slot-scope="{row}">
            <el-input v-model.trim="row.idNumber" class="long-width" maxlength="18" placeholder="请输入身份证"
                      clearable></el-input>
          </template>
        </el-table-column>
        <el-table-column label="联系邮箱" prop="email">
          <template slot-scope="{row}">
            <el-input v-model.trim="row.email" class="long-width" placeholder="请输入邮箱" clearable></el-input>
          </template>
        </el-table-column>

        <el-table-column label="是否队长" width="100">
          <template slot-scope="scope">
            <el-radio v-model="scope.row.role" :label="2" @change="handleSwitchChange(scope.$index)"></el-radio>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="100">
          <template slot-scope="scope">
            <span class="del" @click="handleDel(false,scope.$index)">删除</span>
          </template>
        </el-table-column>
      </el-table>

      <div class="bottom">
        <el-button @click="handleBack">返 回</el-button>
        <el-button :loading="loading" type="primary" @click="handleSubmit">提交</el-button>
      </div>

      <div class="view-personal-center" @click="handleView">
        <img src="../../assets/img/sign-up-management/people.png"/>
        <span>查看报名信息</span>
      </div>
    </div>

    <join-desc-dialog v-model="showJoinDesc" @on-agree="realSubmit"/>
  </div>
</template>
<script>
  import {isMobile, isIdCard, isEmail, isName} from "@/utils/validate"
  import genderOptions from "@/const/genderOptions"
  import {signUp, getOrgNumByName, dictByType} from "@/api/sign-up-management"
  import JoinDescDialog from './components/join-desc-dialog'
  import schoolData, {allData} from '@/const/school';

  export default {
    name: '',
    data() {
      return {
        genderOptions,
        schoolData,
        allData,
        model: {
          groupName: '',
          province: '',
          orgName: '',
          college: '',
          major: ''
        },
        rules: {
          // avatar: [{required: true, message: "请上传团队头像"}],
          groupName: [{required: true, message: "请输入团队名称", trigger: "blur"}],
          province: [
            {required: true, message: "请输入省份", trigger: "blur"}
          ],
          orgName: [
            {required: true, message: "请输入学校名称", trigger: "blur"}
          ],
          college: [
            {required: true, message: "请输入学院/系", trigger: "blur"}
          ],
          major: [
            {required: true, message: "请输入专业名称", trigger: "blur"}
          ],
        },
        teacherTableData: [], // 指导老师
        playerTableData: [],
        loading: false,
        provinceOptions: [],
        showJoinDesc: false,
        maxSignUps: window._CONFIG['MAX_SIGN_UP_NUMS'] || 10,
        maxJoins: window._CONFIG['MAX_JOIN_NUMS'] || 5,
        maxTeachers: window._CONFIG['MAX_TEACHER_NUMS'] || 2,
        maxPlayers: window._CONFIG['MAX_PLAYER_NUMS'] || 2
      }
    },
    components: {
      JoinDescDialog
    },
    created() {
      this.dictByType();
    },
    methods: {
      async dictByType() {
        let res = await dictByType('geo_province_division');
        if (!res || !res.data) return;
        if (res.data.code != 0) return;
        this.provinceOptions = Object.assign([], res.data.data);
      },

      querySearch(queryString, cb) {
        let list = [];
        if (this.model.province) {
          let obj = this.schoolData.find(val => {
            return val.province_code == this.model.province;
          })
          if (obj) {
            list = obj.all_universities;
          }
        } else {
          list = this.allData
        }

        let results = queryString ? list.filter(this.createFilter(queryString)) : list;
        // 调用 callback 返回建议列表的数据
        cb(results);
      },
      createFilter(queryString) {
        return (val) => {
          return (val.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
        };
      },
      // 添加指导老师
      handleAddTeacher() {
        let {teacherTableData = [], maxTeachers} = this;
        if (teacherTableData.length == maxTeachers) {
          this.$message.warning(`最多添加${maxTeachers}位指导老师`);
          return;
        }
        let obj = {
          name: '',
          mobile: '',
          sex: 0,
          role: 0
        }

        this.teacherTableData.push(obj);
      },
      // 添加选手
      handleAddPlayer() {
        let {playerTableData = [], maxPlayers} = this;

        if (playerTableData.length == maxPlayers) {
          this.$message.warning(`最多添加${maxPlayers}位选手`);
          return;
        }
        let obj = {
          name: '',
          mobile: '',
          sex: 0,
          role: 1,
        }

        this.playerTableData.push(obj);
      },
      handleSwitchChange(aimIndex) {
        // console.log("aimIndex", aimIndex)
        this.playerTableData.forEach((val, index) => {
          if (index != aimIndex) {
            val.role = 1
          } else {
            val.role = 2;
          }
        })
      },
      handleSubmit() {
        let {orgName = ''} = this.model;
        let {maxSignUps, maxJoins} = this;
        this.$refs['form'].validate(async (valid) => {
          if (!valid) return;
          let flag = this.anotherValidate();
          if (!flag) return;

          let res = await getOrgNumByName({orgName});
          if (!res || !res.data) return;
          if (res.data.code != 0) return;

          if (Number(res.data.data) >= this.maxSignUps) {
            this.$message.error({
              message: `1、每个学校仅限${maxSignUps}支队伍报名、${maxJoins}支队伍参加比赛，当前学校报名团队已超过最大数量，请联系指导教师确认报名参赛资格。<br/>2、如果您是指导老师，请及时联系对接的市场销售人员，并在校内商定选拔出不大于${maxJoins}支的参赛队伍。`,
              duration: 0,
              showClose: true,
              dangerouslyUseHTMLString: true,
            })
            return;
          }

          this.$message.success(`每个学校仅限${maxSignUps}支队伍报名,请知悉`)
          setTimeout(() => {
            this.showJoinDesc = true;
          }, 2000)
          this.showJoinDesc = true;
        })
      },
      anotherValidate() {
        let {teacherTableData = [], playerTableData = []} = this;

        if (teacherTableData.length < 1) {
          this.$message.error('请至少添加1位指导老师');
          return false;
        }

        for (let i = 0; i < teacherTableData.length; i++) {
          let {name, mobile, idNumber, email} = teacherTableData[i];
          if (!name || !mobile || !idNumber || !email) {
            this.$message.error(`请将'指导老师'表格第${i + 1}行数据填写完整`);
            return false;
          }

          if (!isMobile(mobile)) {
            this.$message.error(`请将'指导老师'表格第${i + 1}行填写正确的手机号`);
            return false;
          }
          if (!isIdCard(idNumber)) {
            this.$message.error(`'指导老师'表格第${i + 1}行填写正确的身份证号码`);
            return false;
          }
          if (!isEmail(email)) {
            this.$message.error(`请将'指导老师'表格第${i + 1}行填写正确的邮箱`);
            return false;
          }
          if (!isName(name)) {
            this.$message.error(`请将'指导老师'表格第${i + 1}行填写正确的姓名`);
            return false;
          }
        }
        if (playerTableData.length != 2) {
          this.$message.error('请添加2位选手');
          return false;
        }

        if (playerTableData.every(val => val.role == 1)) {
          this.$message.error("请选择其中一名选手为'队长'");
          return false;
        }

        for (let i = 0; i < playerTableData.length; i++) {
          let {name, mobile, idNumber, email} = playerTableData[i];
          if (!name || !mobile || !idNumber || !email) {
            this.$message.error(`请将'选手'表格第${i + 1}行数据填写完整`);
            return false;
          }

          if (!isMobile(mobile)) {
            this.$message.error(`请将'选手'表格第${i + 1}行填写正确的手机号`);
            return false;
          }

          if (!isIdCard(idNumber)) {
            this.$message.error(`请将'选手'表格第${i + 1}行填写正确的身份证号码`);
            return false;
          }
          if (!isEmail(email)) {
            this.$message.error(`请将'选手'表格第${i + 1}行填写正确的邮箱`);
            return false;
          }
          if (!isName(name)) {
            this.$message.error(`请将'选手'表格第${i + 1}行填写正确的姓名`);
            return false;
          }
        }
        return true;
      },

      async realSubmit() {
        let {teacherTableData = [], playerTableData = []} = this;
        let detailDTOList = [...teacherTableData, ...playerTableData]
        let params = Object.assign({}, this.model, {detailDTOList})

        this.loading = true;
        try {
          let res = await signUp(params);
          this.loading = false;
          if (!res || !res.data) return;
          if (res.data.code != '0') return;
          this.$message.success('报名成功');
          // this.$emit('on-success')
          this.$router.replace({name: 'bridge'})
        } catch (e) {
          this.loading = false;
        }

      },
      handleDel(flag, index) {
        this.$confirm("确认删除？", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }).then(() => {
          this.realDel(flag, index);
        })
      },
      realDel(flag = true, index) {
        if (flag) {
          this.teacherTableData.splice(index, 1)
        } else {
          this.playerTableData.splice(index, 1)
        }
      },

      handleBack() {
        this.$router.go(-1);
      },
      handleView() {
        this.$router.push({name: 'personal-center'});
      }
    }
  }
</script>

<style scoped lang="scss">
  .sign-up-management {
    width: 100%;
    min-height: 100vh;
    background: url("../../assets/img/home/bg.jpg") no-repeat center;
    background-size: cover;
    font-size: 14px;
    color: #333;
    display: flex;
    justify-content: center;
    align-items: center;
    .sign-content {
      width: 1440px;
      height: 948px;
      overflow-y: auto;
      /*box-sizing: border-box;*/
      padding: 20px;
      border-radius: 10px;
      opacity: 1;
      background: #FFFFFF;
      box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.3);
      position: relative;

      p {
        margin: 0;
        margin-top: 10px;
        font-size: 16px;
      }

      /deep/ .el-input {
        width: 300px;
        height: 32px !important;
      }

      /deep/ .el-input__inner {
        height: 32px !important;
        line-height: 32px !important;
      }

      /deep/ .el-input__icon {
        line-height: 32px !important;
      }

      .join-people {
        display: flex;
        align-items: center;
        .add {
          font-size: 14px;
          cursor: pointer;
          color: #1F88FF;
          margin: 0 10px;
        }
        .el-alert {
          width: 160px;
        }
      }

      .el-table::before {
        z-index: inherit;
      }

      .el-table {
        max-height: 220px;
        overflow-y: scroll;
        margin-bottom: 20px;
        .del {
          cursor: pointer;
          color: #EA2020;
        }
        /deep/ .el-input {
          width: 150px;
        }
        /deep/ .long-width {
          width: 220px !important;
        }
      }

      .player-table {
        max-height: 300px;
      }

      .bottom {
        text-align: center;
      }

    }
    /deep/ .el-radio__label {
      display: none;
    }
    .view-personal-center {
      position: absolute;
      right: 5px;
      top: 8px;
      width: 120px;
      height: 36px;
      border-radius: 4px;
      opacity: 1;
      background: #F0F7FF;
      font-size: 14px;
      color: #0084FF;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      span {
        margin-left: 5px;
      }
    }
  }
</style>